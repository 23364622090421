import styled from "styled-components";

export const ContentContainer = styled.div`
  @media (min-width: 1024px) {
    padding: 4.375rem;
  }
  background: ${props => props.theme.colors.blue};
  color: ${props => props.theme.colors.white};
  h2 {
    font-size: 3.125rem;
    @media (min-width: 1024px) {
      font-size: 5.625rem;
    }
  }
`;