import * as React from "react";
import Content from "../../Content";
import { Section } from "../../styles";
import { ContentContainer } from "./styles";

export const DefaultPageComponent = ({
  contentComponent,
  title,
  content
}) => {

  const PageContent = contentComponent || Content;

  return (
    <Section variant="blue">
      <ContentContainer>
        <h2>
          {title}
        </h2>
        <PageContent content={content} />
      </ContentContainer>
    </Section>
  );
}