import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Layout from "../components/Core/Layout";
import { HTMLContent } from "../components/Content";
import { DefaultPageComponent } from "../components/Pages/Default";

// eslint-disable-next-line
export const DefaultPageTemplate = (props) => {
  return (
    <DefaultPageComponent {...props} />
  );
};

DefaultPageTemplate.propTypes = {
  // TODO
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
};

const DefaultPage = ({ data }) => {
  const { markdownRemark: content } = data;
  const { frontmatter } = content;
  return (
    <Layout templateUsed="default">
      <DefaultPageTemplate
        contentComponent={HTMLContent}
        title={frontmatter.title}
        content={content.html}
      />
    </Layout>
  );
};

DefaultPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default DefaultPage;

export { Head } from "../components/Core/HtmlHead";

export const defaultPageQuery = graphql`
  query defaultPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter{
        title
      }
    }
  }
`;